/** Sunny Tian */

:root {
  --white-background: whitesmoke;
  --black-text: rgb(16, 16, 16);
  --gray-text: rgb(105, 105, 105);
  --orange: #FA5B33;
  --blood-orange: #FF3943;
  --tennis-ball: #CAFD5B;
  --tennis-ball-green: #C3F335;
  --sea-blue: #99DAD1;
}

body {
  width: 100vw;
  height: 100vh;

  background-color: var(--white-background);

  font-family: 'Public Sans', 'Helvetica Neue', sans-serif;
  text-wrap: wrap;
  color: var(--black-text);
  font-size: 1.2rem;

  margin: auto;
  padding: 2rem;
}

section {
  padding-bottom: 2rem;
}

h2, h3 {
  padding-top: 0.6rem;
  padding-bottom: 0.2rem;
}

a {
  text-decoration: underline;
}

a:hover {
  transition: 0.2s;
  color: blue;
}

.projects {
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.links {
  padding-top: 0.4rem;

  display: flex;
  flex-direction: row;

  color: var(--black-text);
  font-size: 1rem;

  div {
    padding-right: 1rem;
  }
}

.tags {
  font-size: 1rem;
  color: var(--gray-text);
}

.blurb {
  color: var(--gray-text);
  padding-left: 1rem;
}

.footer {
  /* position: fixed;
  bottom: 2rem; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    padding-right: 1rem;
  }
}

.header {
  /* position: fixed;
  top: 2rem; */
}

.flex-col {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 400px) {
  /* For mobile: */
  .project-card {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .project-card {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .project-card {
    margin-right: 2rem;
  }
}